<template>
  <div
    v-if="activeUserInfo.displayName"
    class="the-navbar__user-meta flex items-center w-full justify-between"
  >
    <div class="flex items-center">
      <feather-icon
        icon="UserIcon"
        svg-classes="w-5 h-5 mr-2"
      />
      <span class="font-semibold">{{ `Welcome, ${activeUserInfo.displayName}`}}</span>
    </div>
    <div v-if="$route.fullPath.search('home-sell') >= 0" class="flex items-center">
      <a
        href="https://assets.lh.co.th/image/upload/v1601809396/website-pro/documentations/manual/cms/Homeforsale_Mannual2020_zwmxu9.pdf"
        target="_blank"
        class="text-danger"
      >
        กรุณาอ่าน!! เงื่อนไขการสร้างบ้านพร้อมขายแบบใหม่ คลิกที่นี่
      </a>
    </div>
    <vs-button
      type="flat"
      class="text-right leading-tight block"
      @click.stop="logout"
    >
      <div class="flex items-center space-x-2">
        <span class="font-semibold">Logout</span>
        <feather-icon
          icon="LogOutIcon"
          svg-classes="w-5 h-5 stroke-current"
          @click.stop="logout"
        />
      </div>
    </vs-button>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  data() {
    return {}
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    logout() {
      // // if user is logged in via auth0
      // if (this.$auth.profile) this.$auth.logOut()

      // if user is logged in via firebase
      const firebaseCurrentUser = firebase.auth().currentUser

      if (firebaseCurrentUser) {
        firebase
          .auth()
          .signOut()
          .then(() => {
            this.$router.push('/pages/login').catch(() => {})
          })
      }

      // If JWT login
      if (localStorage.getItem('accessToken')) {
        localStorage.removeItem('accessToken')
        this.$router.push('/pages/login').catch(() => {})
      }

      // Change role on logout. Same value as initialRole of acj.js
      this.$acl.change('guest')
      localStorage.removeItem('userInfo')

      // This is just for demo Purpose. If user clicks on logout -> redirect
      this.$router.push('/pages/login').catch(() => {})
    },
  },
}
</script>
