/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
export default [
  // // Dashboard
  // {
  //   url: '/dashboard/analytics',
  //   name: 'Dashboard',
  //   icon: 'HomeIcon',
  //   i18n: 'Dashboard',
  //   // tag: '2',
  //   // tagColor: 'warning',
  // },
  // Property Management
  {
    header: 'PropertyApp',
    icon: 'PackageIcon',
    i18n: 'PropertyApp',
    items: [
      {
        url: '/property-asset/project',
        name: 'PropertyProject',
        slug: 'property-project',
        icon: 'LayersIcon',
        i18n: 'PropertyProject',
      },
      {
        url: '/property/brand',
        name: 'PropertyBrand',
        slug: 'property-brand',
        icon: 'BoldIcon',
        i18n: 'PropertyBrand',
      },
      {
        url: null,
        name: 'PropertyPlan',
        slug: 'property-plan',
        icon: 'ColumnsIcon',
        i18n: 'PropertyPlan',
        submenu: [
          {
            url: '/property-asset/plan/home-series',
            name: 'PropertyPlanSeries',
            slug: 'property-plan-home-series',
            icon: 'ListIcon',
            i18n: 'PropertyPlanSeries',
          },
          {
            url: '/property-asset/plan/home-models',
            name: 'PropertyPlanHomeModels',
            slug: 'property-plan-home-models',
            icon: 'ListIcon',
            i18n: 'PropertyPlanHouseModel',
          },
          {
            url: '/property-asset/plan/home-function',
            name: 'PropertyPlanHomeFunction',
            slug: 'property-plan-home-function',
            icon: 'ListIcon',
            i18n: 'PropertyPlanHomeFunction',
          },
          {
            url: '/property-asset/plan/condo-function',
            name: 'PropertyPlanCondoFunction',
            slug: 'property-plan-condo-function',
            icon: 'ListIcon',
            i18n: 'PropertyPlanCondoFunction',
          },
          {
            url: '/property-asset/plan/activity-icons',
            name: 'PropertyPlanActivityIcon',
            slug: 'property-plan-activity-icons',
            icon: 'ListIcon',
            i18n: 'PropertyPlanActivityIcon',
          },
          {
            url: '/property-asset/plan/master-gallery-homesell',
            name: 'PropertyPlanMasterGallery',
            slug: 'property-plan-master-gallery-homesell',
            icon: 'ListIcon',
            i18n: 'PropertyPlanMasterGallery',
          },
        ],
      },
      {
        url: '/property/promotion',
        name: 'PropertyPromotion',
        slug: 'property-promotion',
        icon: 'TagIcon',
        i18n: 'PropertyPromotion',
        tag: 'promotion',
        tagColor: 'danger'
      },
      // {
      //   url: '/property/review',
      //   name: 'PropertyReview',
      //   slug: 'property-review',
      //   icon: 'MessageCircleIcon',
      //   i18n: 'PropertyReview',
      // },
      {
        url: null,
        name: 'PropertySetting',
        icon: 'SettingsIcon',
        i18n: 'PropertySetting',
        submenu: [
          {
            url: '/property/type',
            name: 'PropertyType',
            slug: 'property-type',
            icon: 'ListIcon',
            i18n: 'PropertyType',
          },
          {
            url: '/property/zone',
            name: 'PropertyZone',
            slug: 'property-zone',
            icon: 'GridIcon',
            i18n: 'PropertyZone',
          },
          // {
          //   url: '/property/geo-info',
          //   name: 'PropertyGeoInfo',
          //   slug: 'property-geo-info',
          //   icon: 'GridIcon',
          //   i18n: 'PropertyGeoInfo',
          // },
        ],
      },
    ],
  },
  // Website Management
  {
    header: 'WebsiteMgtApp',
    icon: 'PackageIcon',
    i18n: 'WebsiteMgtApp',
    items: [
      {
        url: '/landing-pages',
        name: 'LandingPage',
        slug: 'page-management',
        icon: 'FileIcon',
        i18n: 'LandingPage',
      },
      {
        url: null,
        name: 'LHContentApp',
        icon: 'FileTextIcon',
        i18n: 'LHContentApp',
        submenu: [
          {
            url: '/lh-contents/motivo-magazines',
            name: 'LHContentMotivoMagazine',
            slug: 'motivo-magazine',
            // icon: 'BookOpenIcon',
            i18n: 'LHContentMotivoMagazine',
          },
          {
            url: '/lh-contents/tvc',
            name: 'LHContentTvc',
            slug: 'tvc',
            // icon: 'VideoIcon',
            i18n: 'LHContentTvc',
          },
          {
            url: '/lh-contents/project-review',
            name: 'LHContentProjectReview',
            slug: 'project-review',
            i18n: 'LHContentProjectReview',
          },
          {
            url: '/lh-contents/community',
            name: 'LHContentCommunity',
            slug: 'lh-community',
            // icon: 'FileTextIcon',
            i18n: 'LHContentCommunity',
          },
          {
            url: '/lh-contents/living-tips',
            name: 'LHContentLivingTips',
            slug: 'living-tip',
            // icon: 'FileTextIcon',
            i18n: 'LHContentLivingTips',
          },
        ],
      },
      {
        url: '/seo',
        name: 'SeoManagement',
        slug: 'seo-management',
        icon: 'EyeIcon',
        i18n: 'SeoManagement',
      },
      {
        url: '/redirection',
        name: 'RedirectionManagement',
        slug: 'redirection-management',
        icon: 'LinkIcon',
        i18n: 'RedirectionManagement',
      },
      {
        url: '/blacklist-email',
        name: 'BlackListEmailManagement',
        slug: 'blacklist-email-management',
        icon: 'LinkIcon',
        i18n: 'BlackListEmailManagement',
      },
      {
        url: '/banners',
        name: 'BannerManagement',
        slug: 'banner-management',
        icon: 'TargetIcon',
        i18n: 'BannerManagement',
      },
      {
        url: '/highlights',
        name: 'BannerManagement',
        slug: 'banner-management',
        icon: 'TargetIcon',
        i18n: 'HighlightManagement',
      },
      {
        url: '/footers',
        name: 'FooterManagement',
        slug: 'footer-management',
        icon: 'TargetIcon',
        i18n: 'FooterManagement',
      },
      {
        url: '/vdo-project',
        name: 'VDO Project Clip',
        slug: 'project-vdo-management',
        icon: 'TargetIcon',
        i18n: 'ProjectVdoManagement',
      },
      {
        url: '/csr',
        name: 'LH CSR',
        slug: 'csr-management',
        icon: 'TargetIcon',
        i18n: 'CsrManagement',
      },
      // {
      //   url: null,
      //   name: 'PropertySetting',
      //   icon: 'SettingsIcon',
      //   i18n: 'PropertySetting',
      //   submenu: [
      //     {
      //       url: '/website/menu',
      //       name: 'MenuManagement',
      //       slug: 'menu-management',
      //       icon: 'MenuIcon',
      //       i18n: 'MenuManagement',
      //     },
      //     {
      //       url: '/website/footer',
      //       name: 'FooterManagement',
      //       slug: 'footer-management',
      //       icon: 'MenuIcon',
      //       i18n: 'FooterManagement',
      //     },
      //   ],
      // },
    ],
  },
  // Admin Tools
  {
    header: 'AdminToolsMgtApp',
    icon: 'PackageIcon',
    i18n: 'AdminToolsMgtApp',
    items: [
      {
        url: '/admin/users',
        name: 'AdminUserManagement',
        icon: 'UsersIcon',
        slug: 'admin-user-management',
        i18n: 'AdminUserManagement',
      },
      {
        url: '/user-groups',
        name: 'UserGroupManagement',
        icon: 'UsersIcon',
        slug: 'user-group-management',
        i18n: 'UserGroupManagement',
      },
      // {
      //   url: '/user/user-list',
      //   name: 'UserMgtApp',
      //   icon: 'UsersIcon',
      //   slug: 'admin-user-management',
      //   i18n: 'UserMgtApp',
      // },
    ],
  },
  // Other
  {
    header: 'Others',
    icon: 'PackageIcon',
    i18n: 'Others',
    items: [
      {
        url: '/other/contact-us',
        name: 'ContactUs',
        icon: 'PhoneIcon',
        slug: 'contact-us-management',
        i18n: 'ContactUs',
      },
      {
        url: '/other/report',
        name: 'Report',
        icon: 'FileTextIcon',
        slug: 'report-management',
        i18n: 'Report',
      },
      {
        url: '/other/manual',
        name: 'ManualApp',
        icon: 'BookOpenIcon',
        slug: 'manual-management',
        i18n: 'ManualApp',
      }
    ],
  },
]
