import defer from './defer'

const ownProp = Object.prototype.hasOwnProperty

export function createCloudinary() {
  const deferred = defer()

  return {
    notify() {
      deferred.resolve()
    },

    wait() {
      return deferred.promise
    },

    render(config) {
      this.wait().then(() => {
        const cloudinary = window.cloudinary.createMediaLibrary(
          config.options,
          config.functionCallback,
          config.element,
        )
        window.ml = cloudinary
      })
    },

    reset(widgetId) {
      if (typeof widgetId === 'undefined') {
        return
      }

      this.assertLoaded()
      // this.wait().then(() => window.cloudinary.reset(widgetId))
    },

    execute(widgetId) {
      if (typeof widgetId === 'undefined') {
        return
      }

      this.assertLoaded()
      // this.wait().then(() => window.cloudinary.execute(widgetId))
    },

    checkLoad() {
      if (
        ownProp.call(window, 'cloudinary') &&
        ownProp.call(window.cloudinary, 'createMediaLibrary')
      ) {
        this.notify()
      }
    },

    assertLoaded() {
      if (!deferred.resolved()) {
        throw new Error('ReCAPTCHA has not been loaded')
      }
    },
  }
}

const cloudinary = createCloudinary()

// if (typeof window !== 'undefined') {
//   window.cloudinary =  cloudinary.notify
// }

export default cloudinary
