import { onMounted, onBeforeMount } from '@vue/composition-api'
import { sha256 } from 'js-sha256'
import env from '@/env'
import cloudinary from '../cloudinary-wrapper'

export default {
  name: 'CldInitial',
  props: {
    loadScript: {
      type: Boolean,
      default: true,
    },
    scriptId: {
      type: String,
      default: '__CLOUDINARY_SCRIPT',
    },
  },
  render() {
    return null
  },
  setup(props, { root: { $eventBus, $store } }) {
    onBeforeMount(() => {
      if (props.loadScript) {
        if (!document.getElementById(props.scriptId)) {
          const script = document.createElement('script')
          script.id = props.scriptId
          script.src = 'https://media-library.cloudinary.com/global/all.js'
          script.async = true
          script.defer = true

          document.head.appendChild(script)
        }
      }
    })

    const handleSelectedValue = async (data) => {
      await $store.dispatch('cld/insertAssets', data.assets)
      await $eventBus.$emit('cld-selected-value', data.assets)
    }

    onMounted(() => {
      cloudinary.checkLoad()
      const timestamp = new Date().getTime()
      cloudinary.render({
        options: {
          cloud_name: env.VUE_APP_CLOUDINARY_CLOUD_NAME,
          api_key: env.VUE_APP_CLOUDINARY_API_KEY,
          username: env.VUE_APP_CLOUDINARY_USERNAME,
          timestamp,
          signature: sha256(
            `cloud_name=${env.VUE_APP_CLOUDINARY_CLOUD_NAME}&timestamp=${timestamp}&username=${env.VUE_APP_CLOUDINARY_USERNAME}${env.VUE_APP_CLOUDINARY_API_SECRET}`,
          ),
          button_class:
            'cloudinary-button vs-component vs-button mr-3 mb-2 vs-button-primary vs-button-filled',
        },
        functionCallback: {
          insertHandler: handleSelectedValue,
          hideHandler: () => {
            $eventBus.$emit('cld-hide')
            console.log('....')
          },
          errorHandler: (n) => {
            console.log('error handler', n)
          },
          identityHandler: (n) => {
            console.log('error handler', n)
          },
        },
        element: null,
      })
    })
  },
}
